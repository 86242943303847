import React from "react";

import Modal from "theme/components/organisms/Modal";
import { FormattedMessage } from "react-intl";
import LoginModalContent from "./LoginModalContent";

const LoginModal = ({
  message,
  isOpen,
  onRequestClose,
  onLoginSuccess,
  actionOnRegister,
}) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={
        <FormattedMessage
          id="modules.LoginModal.title"
          defaultMessage="Sign in"
        />
      }
    >
      <LoginModalContent
        message={message}
        onLoginSuccess={onLoginSuccess}
        {...(actionOnRegister && {actionOnRegister: actionOnRegister})}
      />
    </Modal>
  );
};

export default LoginModal;
