import { compose, withState, withProps, withHandlers } from "recompose";
import makeCommandDispatcher from "web/core/apollo/makeCommandDispatcher";
import AddProductToWishlistMutation from "theme/modules/Wishlist/AddProductToWishlist/AddProductToWishlistMutation.gql"
import { useHistory } from "react-router";

// Add prop action.
const createRegisterAction = (name, variables, redirectTo) => {
  return {
    actionOnRegister: {
      name: name,
      variables: variables,
      redirectTo: redirectTo,
    }
  };
};

const createWishlistAction = (variables, redirectTo) => {
  const { selectedOptions, sku } = variables;
  return createRegisterAction(
    "ADD_PRODUCT_TO_WISHLIST",
    {
      sku,
      ...(selectedOptions && {
        input: {
          selectedOptions: Object.entries(selectedOptions).map(
            ([option_id, option_value]) => ({option_id, option_value}))
        },
      }),
    },
    redirectTo
  );
}

// Wrap action behaviors.
const withRegisterAction = () =>
  compose(
    withState("actionDone", "setActionDone", false),
    withProps((props) => ({
      history: useHistory(),
      hasAction: !!props?.history?.location?.state?.actionOnRegister,
      hasActionRedirection: !!props?.history?.location?.state?.actionOnRegister?.redirectTo,
      action: props?.history?.location?.state?.actionOnRegister || {},
    })),
    makeCommandDispatcher({
      // Add product to wishlist.
      addProductToWishlist: (props) => () => {
        const { variables } = props.action;

        return {
          options: {
            mutation: AddProductToWishlistMutation,
            variables : {
              ...variables
            },
          }
        };
      },
    }),
    withHandlers({
      /**
       * Fire te action.
       * @param props
       * @returns {(function(): void)|*}
       */
      fireAction: (props) => () => {
        const { name, variables } = props.action;
        switch (name) {
          case 'ADD_PRODUCT_TO_WISHLIST':
              // Fire handler to use mutation.
              props.addProductToWishlist(variables);
            break;
          default:
            console.warn(`Undefined action ${name}`);
        }
      },
      /**
       *  Redirect browser to visited page of the initial action.
       *  Use full redirection to ensure User full query.
       *
       * @param props
       * @returns {(function(): void)|*}
       */
      redirectAction: (props) => () => {
        const { redirectTo } = props.action;
        window.location.href = redirectTo;
      },
    }),
  );

export { withRegisterAction as default, createWishlistAction };