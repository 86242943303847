import React from "react";
import AddProductToWishlist from "theme/modules/Wishlist/AddProductToWishlist";

const ProductItemActions = ({ product, setShowAlertWishlist }) => {
  return (
    <>
      <AddProductToWishlist
        sku={product.sku}
        setShowAlertWishlist={setShowAlertWishlist}
      />
    </>
  );
};

export default ProductItemActions;
