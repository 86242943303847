import React from "react";
import Stack from "theme/components/atoms/Layout/Stack";
import ChooseAccountSelector from "./ChooseAccountSelector";

const LoginModalContent = ({ onLoginSuccess, actionOnRegister }) => {
  return (
    <Stack size="2">
      <div className="login-modal__wrapper">
        <ChooseAccountSelector
          hasGuestMode={false}
          onLoginSuccess={onLoginSuccess}
          {...(actionOnRegister && { actionOnRegister })}
        />
      </div>
    </Stack>
  );
};

export default LoginModalContent;
