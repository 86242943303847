import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber, useIntl, IntlProvider } from "react-intl";

// Can force locale.
// In english for example there is no &nbsp; before % (case in french).
const Percent = ({ value, forcedLocale }) => {
  const intl = useIntl();
  const locale = forcedLocale || intl?.locale || intl?.defaultLocale;

  return (
    <IntlProvider locale={locale}>
      <FormattedNumber
        value={value}
        // eslint-disable-next-line react/style-prop-object
        style="percent"
        currency="EUR"
      />
    </IntlProvider>
  );
}

Percent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Percent;
