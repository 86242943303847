import React from "react";
import Icon from "theme/components/atoms/Icon";

const IconWithHover = ({ initial, hover, size, title }) => {
  return (
    <div className="icon-with-hover">
      <span className="icon-initial">
        <Icon icon={initial} title={title} size={size} />
      </span>
      <span className="icon-hover">
        <Icon icon={hover} title={title} size={size} />
      </span>
    </div>
  );
};

export default IconWithHover;
