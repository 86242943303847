import React from "react";

const Badges = ({ badges }) => {
  return (
    <div className="badges">
      {badges.map((badge, index) => (
        <div key={index}>
          <div className={`badge badge-${badge.css_class}`}>
            {badge.image_url && <img src={badge.image_url} alt={badge.label} />}
            {badge.label}
          </div>
          <br />
        </div>
      ))}
    </div>
  );
};

export default Badges;
