import React from "react";
import Wysiwyg from "theme/modules/WysiwygV2";

const PointBleu = ({ isPointBleu, pointBleuText }) => {
  if (!isPointBleu) {
    return null;
  }
  return pointBleuText ? (
    <div className="pointbleu--message">
      <span className="pointbleu"/>
      <Wysiwyg content={pointBleuText} />
    </div>
  ) : (
    <span className="pointbleu"/>
  );
};

export default PointBleu;
