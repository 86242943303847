import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Price from "./Price";
import ProductPriceSeo from "./ProductPriceSeo";
import CartProductLabel from "theme/modules/Cart/CartItem/CartProductLabel";

const PRICE_MIN = 1;
const PRICE_REAL = 2;
const PRICE_SPECIAL = 3;

// Common component for price display.
const ProductPriceBase = ({
  forceFinalPrice,
  item,
  prefix,
  prices,
  showPriceLabel,
  isSpecialPriceExpired,
  specialPrice,
  withSeo,
}) => {
  const { regularPrice, finalPrice, minPrice } = prices;
  let realFinalPrice =
    item?.priceInfo?.price.priceInclTax || finalPrice.priceInclTax;
  let realRegularPrice = regularPrice.priceInclTax;

  if (isSpecialPriceExpired) {
    realFinalPrice = realRegularPrice;
  }
  // Price type to display.
  const priceType =
    !forceFinalPrice &&
    minPrice?.priceInclTax?.value?.amount &&
    minPrice?.priceInclTax?.value?.amount > realFinalPrice.value.amount
      ? PRICE_MIN
      : realRegularPrice.value.amount <= realFinalPrice.value.amount
      ? PRICE_REAL
      : PRICE_SPECIAL;

  // The price to display.
  const regularPriceToDisplay =
    priceType === PRICE_MIN
      ? minPrice.priceInclTax
      : priceType === PRICE_REAL
      ? realFinalPrice
      : realRegularPrice;

  let isSpecial = priceType === PRICE_SPECIAL;

  if (!isSpecialPriceExpired && specialPrice) {
    realFinalPrice = specialPrice?.priceInclTax;
    isSpecial = true;
  }
  const containerClasses = classNames("product-price", {
    "product-price--special": isSpecial,
  });

  return (
    <div className={containerClasses}>
      {withSeo && <ProductPriceSeo prices={prices} />}
      <div className="product-price__price">
        { prefix &&
          <span className="product-price__prefix">
              { prefix }
          </span>
        }
        {isSpecial && (
          <span className="product-price__special">
            <Price price={realFinalPrice} />
          </span>
        )}
        <span
          className="product-price__regular"
          {...(isSpecial && { "aria-disabled": true })}
        >
          <Price price={regularPriceToDisplay} />
        </span>
        {showPriceLabel && <CartProductLabel item={item} />}
      </div>
    </div>
  );
};

const ProductPrice = ({
  forceFinalPrice,
  item,
  prefix,
  prices,
  showPriceLabel,
  isSpecialPriceExpired,
  specialPrice,
  withSeo = true,
}) => {
  return (
    prices && (
      <ProductPriceBase
        forceFinalPrice={forceFinalPrice}
        item={item}
        prefix={prefix}
        prices={prices}
        showPriceLabel={showPriceLabel}
        isSpecialPriceExpired={isSpecialPriceExpired}
        specialPrice={specialPrice}
        withSeo={withSeo}
      />
    )
  );
};

ProductPrice.propTypes = {
  forceFinalPrice: PropTypes.bool,
  item: PropTypes.any,
  prefix: PropTypes.node,
  prices: PropTypes.shape({
    regularPrice: PropTypes.shape({
      priceInclTax: Price.propTypes.price,
    }).isRequired,
    finalPrice: PropTypes.shape({
      priceInclTax: Price.propTypes.price,
    }).isRequired,
  }).isRequired,
  showPriceLabel: PropTypes.bool,
  withSeo: PropTypes.bool,
};

export default ProductPrice;
