import React from "react";
import Percent from "theme/components/atoms/Typography/Percent";
import Tag from "theme/components/atoms/Tag";

const DISCOUNT_THRESHOLD = 0.01;

const getDiscount = (product) => {
  const regularPrice = product.prices.regularPrice.priceInclTax.value.amount;
  let finalPrice = product.prices.finalPrice.priceInclTax.value.amount;

  if (!product.isSpecialPriceExpired && product.specialPrice) {
    finalPrice = product.specialPrice.priceInclTax.value.amount;
  }
  return (regularPrice - finalPrice) / regularPrice;
};

export const hasLabels = (product) => {
  return getDiscount(product) > DISCOUNT_THRESHOLD;
};

const ProductLabel = ({ product }) => {
  if (!hasLabels(product)) {
    return null;
  }
  const discount = getDiscount(product);

  return (
    <div className="product-label">
      {discount > DISCOUNT_THRESHOLD && (
        <Tag>
          <Percent value={discount} />
        </Tag>
      )}
    </div>
  );
};

export default ProductLabel;
