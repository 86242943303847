import React from "react";
import Percent from "theme/components/atoms/Typography/Percent";
import Tag from "theme/components/atoms/Tag";

const DISCOUNT_THRESHOLD = 0.01;

const getDiscount = (product, priceInfo) => {
  const finalPrice = priceInfo.price.priceInclTax.value.amount;
  const regularPrice = product.prices.regularPrice.priceInclTax.value.amount;

  const discount = (regularPrice - finalPrice) / regularPrice;

  return (discount > DISCOUNT_THRESHOLD) ? discount : null;
};

const CartProductLabel = ({ item: {product, priceInfo} }) => {

  const discount = getDiscount(product, priceInfo);

  if (!discount) {
    return null;
  }
  return (
    <div className="cart-product-label">
      <Tag>
        <Percent value={discount} forcedLocale={"en"}/>
      </Tag>
    </div>
  );
};

export default CartProductLabel;
