import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { defineMessages, useIntl } from "react-intl";
import compose from "recompose/compose";
import Button from "theme/components/atoms/Button/Button";
import Icon from "theme/components/atoms/Icon";
import IconWithHover from "theme/components/atoms/Icon/IconWithHover";
import EnhanceAddProductToWishlist from "./EnhanceAddProductToWishlist";
import { createWishlistAction } from "theme/pages/Register/withRegisterAction";

const messages = defineMessages({
  addToWishlist: {
    id: "modules.Wishlist.AddProductToWishlist.addToWishlist",
    defaultMessage: "Add to wishlist",
  },
  moveToWishlist: {
    id: "modules.Wishlist.AddProductToWishlist.moveToWishlist",
    defaultMessage: "Move to wishlist",
  },
  addedToWishlist: {
    id: "modules.Wishlist.AddProductToWishlist.addedToWishlist",
    defaultMessage: "The item was added to your wishlist",
  },
  removeFromWishlist: {
    id: "modules.Wishlist.AddProductToWishlist.removeFromWishlist",
    defaultMessage: "Remove from wishlist",
  },
  removedFromWishlist: {
    id: "modules.Wishlist.AddProductToWishlist.removedFromWishlist",
    defaultMessage: "The item was removed from your wishlist",
  },
});

export const useProductAddedToWishlistState = () => {
  const [showAlertWishlist, setShowAlertWishlist] = useState(false);
  return { showAlertWishlist, setShowAlertWishlist };
};

const AddProductToWishlist = ({
  action = 'add',
  onAddDone,
  isInWishlist,
  wishlistItem,
  askBeforeMoveToWishlist,
  ...props
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleProductInWishlist = () => {
    props.toggleProductInWishlist();
    props.setShowAlertWishlist(
      isInWishlist
        ? intl.formatMessage(messages.removedFromWishlist)
        : intl.formatMessage(messages.addedToWishlist)
    );
  }

  // Add property to component using React cloneElement.
  const enhancedLoginModal = props.loginModal && React.cloneElement(
    props.loginModal,
    createWishlistAction({
      sku: props?.sku,
      selectedOptions: props?.selectedOptions,
    }, history.location.pathname )
  );

  // Title for icon.
  const wishlistIconTitle = action === "moveToWishlist" ?
    {...messages.moveToWishlist}
    : isInWishlist ?  {...messages.removeFromWishlist}
    : {...messages.addToWishlist}

  return (
    <>
      { enhancedLoginModal }
      <div className={isInWishlist ? "wishlisted" : "not-wishlisted"}>
        <Button
          appearance="default"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            if (action === "moveToWishlist") {
              if (!props?.beforeMoveToWishlist()) {
                return;
              }
            }

            props.setOpenSize && props.setOpenSize(true);
            typeof props.onAddStart === "function" && props.onAddStart();
            props
              .ensureLoggedIn()
              .then(() => {
                handleProductInWishlist();
              })
              .catch((e) => {
                typeof props.onAddDone === "function" && props.onAddDone();
              });
          }}
          size="mini"
          title={ intl.formatMessage(wishlistIconTitle) }
        >
          {(isInWishlist && action === "add") ? (
            <Icon icon="filledHeart"
                  title={intl.formatMessage(messages.removeFromWishlist)}
            />
          ) : (
            <IconWithHover
              initial="wishHeart"
              hover="filledHeart"
              title={intl.formatMessage(wishlistIconTitle)}
            />
          )}
        </Button>
      </div>
    </>
  );
};

AddProductToWishlist.propTypes = {
  action: PropTypes.oneOf(['add', 'moveToWishlist']),
}

export default compose(EnhanceAddProductToWishlist())(AddProductToWishlist);
